import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import style from './NowPlaying.module.css';

interface Track {
	artist?: {
		'#text'?: string;
	};
	'@attr'?: {
		nowplaying?: boolean;
	};
	name?: string;
	date?: {
		uts?: string;
	};
}

interface LastFMResult {
	recenttracks?: {
		track?: Track[];
	};
}

function Bars() {
	return (
		<div className={style.Bars}>
			<div className={style.Bar} />
			<div className={style.Bar} />
			<div className={style.Bar} />
			<div className={style.Bar} />
		</div>
	);
}

export function NowPlaying() {
	const [track, setTrack] = useState<Track | null>(null);

	useEffect(() => {
		function fetchLastFM() {
			fetch(
				`https://ws.audioscrobbler.com/2.0/?method=user.getrecenttracks&user=exactly33&api_key=331c7fb909606171a3b3cda0d2c7d9af&format=json&limit=1`,
			)
				.then(response => response.json())
				.then((resultData: LastFMResult) => {
					const tracks = resultData?.recenttracks?.track;
					if (tracks && tracks[0]) {
						setTrack(tracks[0]);
					}
				});
		}

		fetchLastFM();

		const poll = setInterval(fetchLastFM, 45 * 1000);
		return () => clearInterval(poll);
	}, []);

	const epochNow = new Date().valueOf();
	const epochTrack = Number(track?.date?.uts) * 1000;
	const oldTrack = epochNow - epochTrack > 45 * 60 * 1000;
	const datedTrack = track?.date && !oldTrack;

	const active = track && (track?.date == null || !oldTrack);

	const description = datedTrack
		? `Recently listened to ${track?.name} - ${track?.artist?.['#text']}`
		: `I'm listening to ${track?.name} - ${track?.artist?.['#text']}`;

	return (
		<div className={classNames(style.NowPlaying, active && style.Active)}>
			<span>{description}</span>
			<Bars />
		</div>
	);
}
