import React from 'react';
import style from './Footer.module.css';
import {OutboundLink} from 'gatsby-plugin-google-analytics';

export function Footer() {
	return (
		<footer className={style.Footer}>
			<h6>
				Design, Images, and Website © Justin Mills 2019 -{' '}
				{new Date().getFullYear()}
			</h6>
			<h6>
				{' '}
				<OutboundLink
					href="https://jtsmills.com/rss.xml"
					target="_blank"
					rel="noreferrer"
				>
					Subscribe with{' '}
					<img className={style.RSSIcon} src="/images/rss.svg" alt="RSS" />
				</OutboundLink>{' '}
				| Made with love in Ottawa 🍁
			</h6>
		</footer>
	);
}
