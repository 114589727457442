import React from 'react';
import style from './AboutPanel.module.css';
import {OutboundLink} from 'gatsby-plugin-google-analytics';

export function AboutPanel({
	children,
	to,
}: {
	children: React.ReactNode;
	to: string;
}) {
	return (
		<OutboundLink
			className={style.AboutPanel}
			href={to}
			target="_blank"
			rel="noreferrer"
		>
			{children}
		</OutboundLink>
	);
}
