import {useCanvas, useAnimation} from '../../../../../src/components';
import {useMemo} from 'react';

interface Location {
	x: number;
	y: number;
	angle: number;
	distance: number;
}

interface TurtleType {
	location: Location;
	history: Location[];
}

interface Rule {
	in: string;
	out: string[];
}

interface Props {
	start: string;
	rules: Rule[];
	iterations: number;
	angle: number;
	initial?: Partial<Location>;
}

export function Turtle({rules, start, iterations, angle, initial}: Props) {
	const context = useCanvas();
	const {width, height} = context?.canvas ?? {width: 100, height: 100};

	const results = useMemo(() => {
		const res = [start];
		for (let i = 0; i < iterations; i++) {
			let string = res[res.length - 1];
			for (const rule of rules) {
				const out = rule.out[Math.floor(Math.random() * rule.out.length)];
				string = string.replace(new RegExp(rule.in, 'g'), out);
			}
			res.push(string);
		}
		return res;
	}, [rules, start, iterations]);

	const frame = useAnimation(0, current => current + 1);
	const instructions = results[Math.floor(frame / 33) % results.length];

	if (context) {
		const turtle: TurtleType = {
			// start the turtle at the bottom-middle position pointing up
			location: {
				x: width / 2,
				y: height,
				angle: Math.PI / 2,
				distance: 1,
				...initial,
			},
			history: [],
		};

		context.save();
		for (const instr of instructions) {
			context.beginPath();
			context.moveTo(turtle.location.x, turtle.location.y);

			if (instr === 'f') {
				// move the turtle based on its angle
				turtle.location.distance++;
				const length = height / (turtle.location.distance * 2);
				context.strokeStyle = `hsl(100,45%,${
					(turtle.location.distance / (iterations + 5)) * 50 + 10
				}%)`;
				turtle.location.y -= length * Math.sin(turtle.location.angle);
				turtle.location.x += length * Math.cos(turtle.location.angle);
				// draw our line!
				context.lineTo(turtle.location.x, turtle.location.y);
			} else if (instr === '+') {
				turtle.location.angle -= angle;
			} else if (instr === '-') {
				turtle.location.angle += angle;
			} else if (instr === '[') {
				// store our current location in the history
				turtle.history.push({...turtle.location});
			} else if (instr === ']' && turtle.history.length > 0) {
				// restore our most recent location
				turtle.location = turtle.history.pop() as Location;
			}

			context.closePath();
			context.stroke();
		}
		context.restore();
	}

	return null;
}
