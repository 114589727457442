import React from 'react';
import classNames from 'classnames';
import style from './Grid.module.css';

interface Props {
	padded?: boolean;
	children?: React.ReactNode;
}

export function Grid({children, padded = false}: Props) {
	const classes = classNames(style.Grid, padded && style.Padded);
	return <div className={classes}>{children}</div>;
}
