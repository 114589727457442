import React from 'react';
import {Panel} from '../Panel/Panel';
import style from './Page.module.css';

interface Props {
	title?: string;
	subTitle?: React.ReactNode;
	children: React.ReactNode;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	heroImage?: any;
}

export function Page({title, subTitle, children, heroImage}: Props) {
	const headerMarkup = heroImage ? (
		<Panel fluidImage={heroImage} padding="none">
			<div className={style.Hero}>
				<h2 className={style.Title}>{title}</h2>
				<p className={style.SubTitle}>{subTitle}</p>
			</div>
		</Panel>
	) : (
		<>
			<h2>{title}</h2>
			<h3>{subTitle}</h3>
		</>
	);

	return (
		<div className={style.Page}>
			<div className={style.Header}>{headerMarkup}</div>
			{children}
		</div>
	);
}
