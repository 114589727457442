import React from 'react';
import style from './Tag.module.css';
import {stringToHSL} from '../../utils/stringToHSL';

export function Tag({children}: {children: string}) {
	return (
		<div style={{backgroundColor: stringToHSL(children)}} className={style.Tag}>
			{children}
		</div>
	);
}
