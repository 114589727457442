import React, {useState, useMemo} from 'react';
import {Portal} from '..';
import style from './Modal.module.css';

interface ModalProps {
	children: React.ReactNode;
	close: () => void;
}

function ModalComponent({children, close}: ModalProps) {
	return (
		<Portal onOutsideClick={close} wrapperClasses={style.ModalWrapper}>
			<div className={style.Modal}>{children}</div>
		</Portal>
	);
}

const Modal = React.memo(ModalComponent);

export function useModal() {
	const [active, setActive] = useState(false);

	return useMemo(() => {
		const show = () => setActive(true);
		const hide = () => setActive(false);
		const ModalMarkup = ({children}: {children: React.ReactNode}) =>
			active ? <Modal close={hide}>{children}</Modal> : null;

		return {
			show,
			hide,
			Modal: ModalMarkup,
		};
	}, [active, setActive]);
}
