import React from 'react';
import {useModal} from '../../../../../src/components';
import style from './ISO.module.css';

export function ISO() {
	const {show, Modal} = useModal();

	return (
		<div className={style.ISO}>
			<Modal>
				<div className={style.Container}>
					<iframe
						className={style.Game}
						src="/iso-sand"
						width="100%"
						height="100%"
					/>
					<b>Left click</b> to place materials. <b>Arrow keys</b> move the
					board. <b>Spacebar</b> opens the Materials menu.
				</div>
			</Modal>
			<div className={style.Thumbnail} onClick={show}>
				<h1 className={style.PlayText}>Play</h1>
				<img src="/images/iso.png" />
			</div>
		</div>
	);
}
