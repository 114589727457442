import React, {useContext} from 'react';

type RenderContext = CanvasRenderingContext2D | null;

interface Context {
	renderContext: RenderContext;
	frame: number;
}

const CanvasContext = React.createContext<Context>({
	renderContext: null,
	frame: 0,
});

interface Props {
	children: React.ReactNode;
	style: React.CSSProperties;
}

export function Canvas({children, style}: Props) {
	const defaults = useContext(CanvasContext);
	const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
	const [context, setContext] = React.useState<Context>(defaults);

	React.useEffect(() => {
		if (!context.renderContext) {
			const context2d = canvasRef.current?.getContext('2d');
			setContext({...context, renderContext: context2d ?? null});
		}
	});

	React.useEffect(() => {
		const frame = requestAnimationFrame(() =>
			setContext({...context, frame: frame + 1}),
		);
		return () => cancelAnimationFrame(frame);
	}, [context, setContext]);

	context.renderContext?.clearRect(
		0,
		0,
		canvasRef.current?.width ?? 0,
		canvasRef.current?.height ?? 0,
	);

	return (
		<CanvasContext.Provider value={context}>
			<canvas width="1000" height="700" style={style} ref={canvasRef} />
			{children}
		</CanvasContext.Provider>
	);
}

export function useCanvas() {
	return React.useContext(CanvasContext).renderContext;
}

export function useAnimation(
	initialValue: number,
	valueUpdater: (current: number) => number,
) {
	const animatedValue = React.useRef(initialValue);
	animatedValue.current = valueUpdater(animatedValue.current);
	return animatedValue.current;
}
