import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import classNames from 'classnames';
import style from './Panel.module.css';

interface Props {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	fluidImage?: any;
	title?: string;
	children?: React.ReactNode;
	padding?: 'none' | 'small' | 'medium' | 'large';
	className?: string;
}

export function Panel({
	fluidImage,
	title,
	children,
	className,
	padding = 'medium',
}: Props) {
	const titleMarkup = title ? <h2>{title}</h2> : null;
	const smallPadding = padding === 'small' && style.SmallPadding;
	const mediumPadding = padding === 'medium' && style.MediumPadding;
	const largePadding = padding === 'large' && style.LargePadding;

	const classes = classNames(
		style.Panel,
		smallPadding,
		mediumPadding,
		largePadding,
		className,
	);

	if (fluidImage) {
		return (
			<BackgroundImage className={classes} fluid={fluidImage}>
				{titleMarkup}
				{children}
			</BackgroundImage>
		);
	} else {
		return (
			<div className={classes}>
				{titleMarkup}
				{children}
			</div>
		);
	}
}
