import React from 'react';
import {Nav, Footer} from '..';
import {useStaticQuery, graphql} from 'gatsby';
import style from './ContentLayout.module.css';

import BackgroundImage from 'gatsby-background-image';

interface Props {
	title: string;
	titleLink: string;
	children?: React.ReactNode;
}

export function ContentLayout({title, titleLink, children}: Props) {
	const data = useStaticQuery(graphql`
		query {
			background: file(relativePath: {eq: "bg.jpg"}) {
				childImageSharp {
					fluid(
						quality: 95
						maxWidth: 1920
						traceSVG: {background: "#ccd0cb", color: "#2c363f"}
					) {
						...GatsbyImageSharpFluid_withWebp_tracedSVG
					}
				}
			}
		}
	`);

	const imageData = data.background.childImageSharp.fluid;

	return (
		<div className={style.ContentLayout}>
			<BackgroundImage durationFadeIn={675} fluid={imageData}>
				<Nav subTitle={title} subTitleLink={titleLink} />
			</BackgroundImage>
			<div className={style.Container}>{children}</div>
			<Footer />
		</div>
	);
}
