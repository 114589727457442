import React, {useState} from 'react';
import {Canvas} from '../../../../../src/components';
import {Turtle} from './Turtle';
import style from './Turtle.module.css';

export function TurtleControls() {
	const [angle, setAngle] = useState(Math.PI / 8);
	const [rule, setRule] = useState('f+[[X]-X]-f[-fX]+X');

	return (
		<div className={style.Controls}>
			<Canvas
				style={{
					backgroundColor: '#FBAB7E',
					backgroundImage: 'linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%)',
				}}
			>
				<Turtle
					rules={[{in: 'X', out: [rule]}]}
					start="X"
					iterations={5}
					angle={angle}
					initial={{x: 0, angle: Math.PI / 4}}
				/>
			</Canvas>
			<div className={style.Inputs}>
				<div>
					<label>Translation Rule (X → ...)</label>
					<input
						type="text"
						onChange={event => setRule(event.target.value)}
						value={rule}
						placeholder="f+[[X]-X]-f[-fX]+X"
					></input>
				</div>
				<div>
					<label>Angle delta:</label>
					<input
						type="number"
						onChange={event =>
							setAngle((Number(event.target.value) * Math.PI) / 180)
						}
						value={Math.round((angle * 180) / Math.PI)}
					></input>
				</div>
			</div>
		</div>
	);
}
