import React from 'react';
import {Nav, Footer} from '..';
import {Link, useStaticQuery, graphql} from 'gatsby';
import style from './MainLayout.module.css';

import BackgroundImage from 'gatsby-background-image';

interface Props {
	title: string;
	subTitle?: React.ReactNode;
	children?: React.ReactNode;
}

export function MainLayout({title, subTitle, children}: Props) {
	const data = useStaticQuery(graphql`
		query {
			background: file(relativePath: {eq: "bg.jpg"}) {
				childImageSharp {
					fluid(
						quality: 95
						maxWidth: 1920
						traceSVG: {background: "#ccd0cb", color: "#2c363f"}
					) {
						...GatsbyImageSharpFluid_withWebp_tracedSVG
					}
				}
			}
		}
	`);

	const imageData = data.background.childImageSharp.fluid;

	const bodyMarkup =
		children != null ? <div className={style.Body}>{children}</div> : null;

	return (
		<BackgroundImage durationFadeIn={675} fluid={imageData}>
			<div className={style.MainLayout}>
				<Nav />
				<div className={style.Container}>
					<div className={style.Content}>
						<h3 className={style.Title}>{title}</h3>
						{subTitle}
						{bodyMarkup}
					</div>
				</div>
				<Footer />
			</div>
		</BackgroundImage>
	);
}
