import React from 'react';
import {Link} from 'gatsby';
import {OutboundLink} from 'gatsby-plugin-google-analytics';
import style from './Nav.module.css';

interface Props {
	subTitle?: string;
	subTitleLink?: string;
	children?: React.ReactNode;
	image?: string;
}

export function Nav({subTitle, subTitleLink, children}: Props) {
	const childrenMarkup = children ? (
		children
	) : (
		<>
			<Link to="/about">About</Link>
			<Link to="/blog">Blog</Link>
			<OutboundLink
				href="https://github.com/jtsmills"
				target="_blank"
				rel="noreferrer"
			>
				<img src="/images/github.svg" alt="Github" />
			</OutboundLink>
			<OutboundLink
				href="https://ca.linkedin.com/in/jtsmills"
				target="_blank"
				rel="noreferrer"
			>
				<img src="/images/linkedin.svg" alt="LinkedIn" />
			</OutboundLink>
		</>
	);

	return (
		<div className={style.NavWrapper}>
			<div className={style.Nav}>
				<div className={style.NavLogo}>
					<Link className={style.NavTitle} to="/">
						jtsmills
					</Link>
					<Link className={style.NavSub} to={subTitleLink ?? '/'}>
						{subTitle}
					</Link>
				</div>
				<div className={style.NavActions}>{childrenMarkup}</div>
			</div>
		</div>
	);
}
