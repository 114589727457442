import React from 'react';
import {Panel} from '..';
import style from './Card.module.css';
import Img from 'gatsby-image';

interface Props {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	fluidImage?: any;
	children?: React.ReactNode;
	footer?: React.ReactNode;
}

export function Card({fluidImage, footer, children}: Props) {
	const footerMarkup = footer ? (
		<div className={style.Footer}>{footer}</div>
	) : null;

	return (
		<Panel className={style.Card} padding="none">
			<Img className={style.CardHeader} fluid={fluidImage} />
			<div className={style.CardBody}>
				<div>{children}</div>
				{footerMarkup}
			</div>
		</Panel>
	);
}
