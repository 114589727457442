import React, {useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';

interface Props {
	children: React.ReactNode;
	onOutsideClick?: VoidFunction;
	wrapperClasses?: string;
}

export function Portal({children, onOutsideClick, wrapperClasses}: Props) {
	const element = document.createElement('div');
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const ref = useRef<any>(null);
	useEffect(() => {
		const mount = document.getElementById('portal-root');

		function handleClick(event: Event) {
			if (!ref?.current?.contains(event.target) && onOutsideClick) {
				onOutsideClick();
			}
		}

		if (onOutsideClick) {
			document.addEventListener('mousedown', handleClick);
		}

		mount?.appendChild(element);
		return () => {
			if (onOutsideClick) {
				document.removeEventListener('mousedown', handleClick);
			}

			mount?.removeChild(element);
		};
	}, [element]);

	const markup = (
		<div className={wrapperClasses}>
			<div ref={ref}>{children}</div>
		</div>
	);

	return ReactDOM.createPortal(markup, element);
}
